<div class="pt-4 pb-4">
    <div class="pr-5 pl-5 ml-3 mr-3">
        <form class="tecneplas-login-form" #loginForm="ngForm" [formGroup]="formGroup" (ngSubmit)="loginForm.form.valid && submitForm()" autocomplete="disabled">
            <div class="text-center">
                <img class="mb-5" src="assets/img/logo.svg">
                <h5 i18n>Recuperar contraseña</h5>
            </div>
            <div *ngIf="errorMessage">
                <div class="alert alert-danger" *ngIf="errorMessage" role="alert">
                    {{errorMessage}}
                </div>
            </div>

            <div class="mt-4 mb-4 text-left form-group">
                <label class="text-secondary fw-bold fs-11 lh-2" i18n>Escribe tu email de registro</label>
                <input type="email" formControlName="email" id="inputEmail" class="form-control" required autofocus>
            </div>

            <div class="mt-4 mb-4 text-left form-group">
                <label class="text-secondary fw-bold fs-11 lh-2" i18n>Escribe una contraseña nueva</label>
                <input type="password" formControlName="password" id="inputPassword" class="form-control" required autofocus>
            </div>

            <button class="btn-lg btn-primary" type="submit" i18n>Enviar</button>

        </form>
    </div>
</div>