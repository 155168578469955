<div class="container-fluid">
    <div class="row">
        <nav class="col-md-3 col-lg-2 min-vh-100 p-0 d-md-block sidebar collapse bg-white">
            
            <div class="d-flex pt-4 pb-4">
                <a class="navbar-brand ms-5" [routerLink]="['/products']">
                    <img class="m-auto" src="assets/img/tecneplas-brand.svg" width="160">
                </a>
            </div>
            
            <div class="position-sticky pt-3">
                
            </div>
        </nav>
        <main class="col-md-9 ms-sm-auto col-lg-12">
            <div class="text-end m-4">
                <a class="text-primary" [routerLink]="['/animations']">
                    <i class="bi bi-x-circle fs-1"></i>
                </a>
            </div>
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                    <div class="mt-5">
                        <!-- FORM -->
                        <form #modelNgForm="ngForm" [formGroup]="animationForm" (ngSubmit)="submitAnimationForm()" >

                            <h5 i18n>Nueva animación</h5>
                            <p class="fs-10" i18n>Para empezar, introduce los siguientes datos</p>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label class="text-secondary fw-bold fs-11 lh-2" i18n>Nombre de la animación</label>
                                        <input type="text" class="form-control form-control-lg" required formControlName="name" [ngClass]="[modelNgForm.submitted && animationForm.controls.name.errors?.required ? 'is-invalid' : '']">
                                        <div class="invalid-feedback" *ngIf="animationForm.controls.name.errors?.required" i18n>Debe introducir un nombre</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="mt-3 mb-3 form-group">
                                        <label class="text-secondary fw-bold fs-11 lh-2" i18n>Animación</label>
                                        <input class="form-control" type="file" accept=".led" formControlName="animation" (change)="onChangeAnimation($event)" [ngClass]="[modelNgForm.submitted && animationForm.controls.animation.errors?.required ? 'is-invalid' : '']">
                                        <div class="invalid-feedback" *ngIf="animationForm.controls.animation.errors?.required" i18n>Debe introducir una animación</div>
                                    </div>
                                </div>
                            </div>

                            <button class="mt-2 btn btn-lg btn-outline-primary me-2" [routerLink]="['/animations']" i18n>Cancelar</button>
                            <button class="mt-2 btn btn-lg btn-primary" type="submit" i18n>Aceptar</button>
                            
                        </form>

                    </div>
                </div>
                <div class="col-md-3"></div>
            </div>
        </main>
    </div>
</div>