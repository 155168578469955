import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-layout',
  templateUrl: './account-layout.component.html'
})
export class AccountLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
