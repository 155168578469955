<div class="row">
    <div class="col-12 col-lg-2 col-md-2	col-lg-2 col-xl-2 col-xxl-2 pt-3 mt-2">
        <select #slotDurationInput class="form-select w-50" [ngModel]="slotDuration" (ngModelChange)="onChange($event)">
            <option value="01:00:00" i18n>1 hora</option>
            <option value="00:30:00" i18n>30 minutos</option>
            <option value="00:15:00" i18n>15 minutos</option>
            <option value="00:10:00" i18n>10 minutos</option>
        </select>
    </div>
</div>

<div class="row">
    <div class="col-12 col-lg-10 col-md-10	col-lg-10 col-xl-10 col-xxl-10 pt-3 mb-4 mt-2">
        <full-calendar class="pe-4" #crossWeekly [options]="weeklyOptions" deepChangeDetection="true"></full-calendar>
    </div>
    <div class="col-12 col-lg-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 p-4 pt-3 mt-4 bg-white">
        <div class="row mb-2">
            <div class="col-12 col-lg-12 col-md-12	col-lg-12 col-xl-12 col-xxl-12">
                <h6 class="pb-3 fs-10 fw-bold" i18n>Tus secuencias ({{totalSequences}})</h6>
            </div>
        </div>
        <div *ngIf="sequences.length > 0">
            <div class="row mb-2" *ngFor="let sequence of sequences">
                <div class="col-12 col-lg-12 col-md-12	col-lg-12 col-xl-12 col-xxl-12 pb-1">
                    <span class="fs-12 fw-bold">{{ sequence.name }}</span>
                </div>
                <div class="col-12 col-lg-12 col-md-12	col-lg-12 col-xl-12 col-xxl-12" *ngFor="let sequence_item of sequence.list">
                    <div class="bullet-point mt-2 me-2" [ngClass]="{'bg-daily-bullet-point': sequence.category === 'daily', 'bg-holiday-bullet-point' : sequence.category === 'holiday', 'bg-on_guard-bullet-point' : sequence.category === 'on_guard' }" ></div><span class="fs-11 fw-regular text-secondary">{{ sequence_item.name }}</span>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- start: Modal for adding new sequence to the weekly -->
<ng-template #addCrossWeeklySequenceModal let-modal>
    <div class="modal-header">
        <h6 *ngIf="selectedSequence != null" class="modal-title" id="modal-basic-title" i18n>Editar secuencia</h6>
        <h6 *ngIf="selectedSequence == null" class="modal-title" id="modal-basic-title" i18n>Añadir secuencia</h6>
    </div>
    <form [formGroup]="weeklySequenceForm" (submit)="submitFormAddCrossWeeklySequence()">
        <div class="modal-body">
            <div class="row">
                <div class="col-6 col-lg-6 col-md-6	col-lg-6 col-xl-6 col-xxl-6 mb-3">
                    <label for="formCategory" class="form-label" i18n>Elige tu secuencia</label>
                    <select class="form-select" formControlName="id_sequence" formControlName="id_sequence">
                        <option value="" i18n>Seleccionar secuencia...</option>
                        <optgroup label="{{ sequence.name }}" *ngFor="let sequence of sequences">
                            <option *ngFor="let sequence_item of sequence.list" [value]="sequence_item._id">{{
                                sequence_item.name }}</option>
                        </optgroup>
                    </select>
                </div>
            </div>
            <div class="row" *ngIf="selectedSequence == null">
                <div class="col-6 col-lg-6 col-md-6	col-lg-6 col-xl-6 col-xxl-6 mb-3">
                    <label for="formFromTime" class="form-label">De</label>
                    <input type="time" step="1" class="form-control" id="formFromTime" formControlName="from_time">
                </div>
                <div class="col-6 col-lg-6 col-md-6	col-lg-6 col-xl-6 col-xxl-6 mb-3">
                    <label for="formToTime" class="form-label">A</label>
                    <input type="time" step="1" class="form-control" id="formToTime" formControlName="to_time">
                </div>
            </div>
        </div>
        <div class="modal-footer bg-body">
            <button type="button" class="btn btn-outline-primary me-auto" (click)="closeModal()" i18n>Cancelar</button>
            <button type="submit" class="btn btn-primary" [disabled]="!weeklySequenceForm.valid" i18n>Guardar cambios</button>
            <button *ngIf="selectedSequence != null" type="button" class="btn btn-danger" (click)="deleteWeeklySequence()" i18n>Eliminar</button>
        </div>
    </form>
</ng-template>
<!-- end: Modal for adding new sequence to a date or muliple dates -->