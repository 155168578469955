<div class="pt-4 pb-4">
    <div class="pr-5 pl-5 ml-3 mr-3">
            <div class="text-center">
                <img class="mb-3" src="assets/img/logo.svg">
                <p><i class="bi bi-check2 h1 text-success"></i></p>
                <p class="fs-10" i18n>¡Perfecto! En breve recibirás una correo electrónico con el enlace para poder modificar tu contraseña. </p>
            </div>

            <button class="btn-lg btn-primary" [routerLink]="['/account/login']" i18n>Volver al Inicio de sesión</button>
    </div>
</div>