export const dib0 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
	0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
	0x00, 0x00, 0x00, 0x00
];

export const dib1 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x01, 0xE0, 0x01, 0xE0, 0x01, 0xE0, 0x01, 0xE0, 0x1F, 0xFE,
	0x1F, 0xFE, 0x1F, 0xFE, 0x1F, 0xFE, 0x01, 0xE0, 0x01, 0xE0, 0x01, 0xE0,
	0x01, 0xE0, 0x00, 0x00
];

export const dib2 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x01, 0xE0, 0x01, 0x20, 0x01, 0x20, 0x01, 0x20, 0x1F, 0x3E,
	0x10, 0x02, 0x10, 0x02, 0x1F, 0x3E, 0x01, 0x20, 0x01, 0x20, 0x01, 0x20,
	0x01, 0xE0, 0x00, 0x00
];

export const dib3 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x07, 0xF8, 0x03, 0xF0, 0x11, 0xE2, 0x1D, 0xEE, 0x1F, 0xFE,
	0x1F, 0xFE, 0x1F, 0xFE, 0x1F, 0xFE, 0x1D, 0xEE, 0x11, 0xE2, 0x03, 0xF0,
	0x07, 0xF8, 0x00, 0x00
];

export const dib4 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x07, 0xF8, 0x02, 0x10, 0x11, 0x22, 0x19, 0x26, 0x17, 0x3A,
	0x10, 0x02, 0x10, 0x02, 0x17, 0x3A, 0x19, 0x26, 0x11, 0x22, 0x02, 0x10,
	0x07, 0xF8, 0x00, 0x00
];

export const dib5 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x01, 0xF8, 0x0C, 0x60, 0x07, 0xFC, 0x00, 0x66, 0x00, 0x6C,
	0x07, 0x60, 0x0C, 0x60, 0x19, 0xF8, 0x1B, 0xFC, 0x1F, 0x9E, 0x18, 0x60,
	0x0F, 0xE0, 0x00, 0x00
];

export const dib6 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x12, 0x8E, 0x12, 0x8E, 0x12, 0x82, 0x12, 0xE2, 0x12, 0xE4,
	0x1E, 0xA8, 0x1E, 0xA8, 0x12, 0xA8, 0x12, 0xC8, 0x12, 0xCA, 0x12, 0x8A,
	0x12, 0x84, 0x00, 0x00
];

export const dib7 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x12, 0xF4, 0x12, 0xF4, 0x12, 0x14, 0x12, 0x74, 0x12, 0x64,
	0x1E, 0xC4, 0x1E, 0xC4, 0x12, 0x84, 0x12, 0x94, 0x12, 0x94, 0x12, 0xF6,
	0x12, 0x64, 0x00, 0x00
];

export const dib8 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x01, 0xE0, 0x07, 0xF8, 0x0F, 0xFC, 0x1F, 0xFE, 0x1F, 0xFE,
	0x1F, 0xFE, 0x1F, 0xFE, 0x1F, 0xFE, 0x1F, 0xFE, 0x0F, 0xFC, 0x07, 0xF8,
	0x01, 0xE0, 0x00, 0x00
];

export const dib9 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x0F, 0x80, 0x03, 0xE0, 0x01, 0xF0, 0x00, 0xF8, 0x00, 0x7C,
	0x00, 0x7C, 0x00, 0x7C, 0x00, 0x7C, 0x00, 0xF8, 0x01, 0xF0, 0x03, 0xE0,
	0x0F, 0x80, 0x00, 0x00
];

export const dib10 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x01, 0xE0, 0x06, 0x18, 0x09, 0xE4, 0x09, 0xE4, 0x10, 0xC2,
	0x10, 0xC2, 0x10, 0xE2, 0x10, 0x02, 0x08, 0xC4, 0x08, 0xC4, 0x06, 0x18,
	0x01, 0xE0, 0x00, 0x00
];

export const dib11 = [
	// Offset 0x00000000 to 0x0000001B
  /*0x00, 0x00, 0x1C, 0x00, 0x18, 0x00, 0x14, 0xF0, 0x03, 0x08, 0x02, 0x04,
	0x04, 0x02, 0x04, 0x02, 0x04, 0x02, 0x04, 0x02, 0x02, 0x04, 0x01, 0x08,
	0x00, 0xF0, 0x00, 0x00*/

	0x00, 0x00, 0x00, 0xF0, 0x01, 0x08, 0x02, 0x04, 0x04, 0x02, 0x04, 0x02,
	0x04, 0x02, 0x04, 0x02, 0x02, 0x04, 0x03, 0x08, 0x14, 0xF0, 0x18, 0x00, 
	0x1C, 0x00, 0x00, 0x00
	
];

export const dib12 = [
	// Offset 0x00000000 to 0x0000001B
	/*0x00, 0x00, 0x01, 0xE0, 0x06, 0x18, 0x08, 0x04, 0x18, 0x06, 0x08, 0x04,
	0x06, 0x18, 0x01, 0xE0, 0x00, 0x40, 0x00, 0x40, 0x01, 0xF0, 0x00, 0x40,
	0x00, 0x40, 0x00, 0x00*/
	
	0x00, 0x00, 0x0, 0x40, 0x00, 0x40, 0x01, 0xF0, 0x00, 0x40, 0x00, 0x40, 
	0x01, 0xE0, 0x06, 0x18, 0x08, 0x04, 0x18, 0x06, 0x08, 0x04, 0x06, 0x18,
	0x01, 0xE0, 0x00, 0x00
];

export const dib13 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x01, 0x20, 0x01, 0x20, 0x01, 0x20, 0x01, 0xE0, 0x13, 0xF2,
	0x0B, 0xF4, 0x07, 0xF8, 0x00, 0xC0, 0x01, 0x20, 0x02, 0x10, 0x01, 0x20,
	0x00, 0xC0, 0x00, 0x00
];

export const dib14 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x01, 0x20, 0x01, 0x20, 0x01, 0x20, 0x0F, 0xFC, 0x07, 0xF8,
	0x03, 0xF0, 0x0F, 0xFC, 0x00, 0xC0, 0x01, 0x20, 0x02, 0x10, 0x01, 0x20,
	0x00, 0xC0, 0x00, 0x00
];

export const dib15 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x00, 0xF0, 0x01, 0xF8, 0x01, 0xF8, 0x01, 0xF8, 0x01, 0x98,
	0x01, 0x88, 0x01, 0x88, 0x01, 0x88, 0x01, 0x88, 0x01, 0x88, 0x01, 0x88,
	0x01, 0xF8, 0x00, 0x00
];

export const dib16 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x00, 0x40, 0x00, 0xE0, 0x01, 0xF0, 0x03, 0xF8, 0x07, 0xFC,
	0x0F, 0xFE, 0x00, 0xE0, 0x00, 0xE0, 0x00, 0xE0, 0x00, 0xE0, 0x00, 0xE0,
	0x00, 0xE0, 0x00, 0x00
];

export const dib17 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x00, 0xE0, 0x00, 0xE0, 0x00, 0xE0, 0x00, 0xE0, 0x00, 0xE0,
	0x00, 0xE0, 0x0F, 0xFE, 0x07, 0xFC, 0x03, 0xF8, 0x01, 0xF0, 0x00, 0xE0,
	0x00, 0x40, 0x00, 0x00
];

export const dib18 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x00, 0x00, 0x00, 0x40, 0x00, 0x60, 0x00, 0x70, 0x00, 0x78,
	0x1F, 0xFC, 0x1F, 0xFE, 0x1F, 0xFC, 0x00, 0x78, 0x00, 0x70, 0x00, 0x60,
	0x00, 0x40, 0x00, 0x00
];

export const dib19 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x00, 0x00, 0x00, 0x80, 0x01, 0x80, 0x03, 0x80, 0x07, 0x80,
	0x0F, 0xFE, 0x1F, 0xFE, 0x0F, 0xFE, 0x07, 0x80, 0x03, 0x80, 0x01, 0x80,
	0x00, 0x80, 0x00, 0x00
];

export const dib20 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x07, 0x1C, 0x08, 0xA2, 0x08, 0xA2,
	0x08, 0xE2, 0x07, 0x5C, 0x02, 0x08, 0x14, 0x90, 0x18, 0x60, 0x00, 0x00,
	0x00, 0x00, 0x00, 0x00
];

export const dib21 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x00, 0xC0, 0x00, 0xC0, 0x00, 0xC0, 0x0F, 0xFC, 0x0A, 0x84,
	0x04, 0x28, 0x05, 0x28, 0x02, 0x90, 0x02, 0x10, 0x01, 0x60, 0x01, 0x20,
	0x00, 0xC0, 0x00, 0x00
];

export const dib22 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x07, 0xFE, 0x0E, 0xD6, 0x15, 0x2A, 0x16, 0xD6, 0x15, 0x2A,
	0x17, 0xFE, 0x18, 0x04, 0x1F, 0xF8, 0x03, 0xE0, 0x0D, 0x58, 0x11, 0x44,
	0x0E, 0x38, 0x00, 0x00
];

export const dib23 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x00, 0x00, 0x02, 0x48, 0x01, 0x50, 0x09, 0x52, 0x06, 0xEC,
	0x01, 0xF0, 0x0F, 0xFE, 0x01, 0xF0, 0x06, 0xEC, 0x09, 0x52, 0x01, 0x50,
	0x02, 0x48, 0x00, 0x00
];

export const dib24 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x00, 0x40, 0x00, 0xE0, 0x0F, 0xFE, 0x0F, 0xFE, 0x07, 0xFC,
	0x03, 0xF8, 0x03, 0xF8, 0x03, 0xF8, 0x03, 0xF8, 0x01, 0xF0, 0x00, 0xE0,
	0x00, 0x40, 0x00, 0x00
];

export const dib25 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x01, 0xE0, 0x03, 0xF0, 0x02, 0x70, 0x03, 0x70, 0x02, 0x70,
	0x03, 0x70, 0x02, 0x70, 0x03, 0x10, 0x02, 0x10, 0x03, 0x10, 0x02, 0x10,
	0x01, 0xE0, 0x00, 0x00
];

export const dib26 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x1F, 0xFE, 0x1F, 0xFE, 0x1E, 0x1E, 0x1C, 0xCE, 0x1D, 0xEE,
	0x1D, 0xEE, 0x0C, 0xCC, 0x02, 0x10, 0x1D, 0xEE, 0x1D, 0x2E, 0x1F, 0xFE,
	0x0F, 0xFC, 0x00, 0x00
];

export const privdib00 = [
	// Offset 0x00000000 to 0x0000001B
	0x02, 0x10, 0x11, 0x22, 0x08, 0x04, 0x01, 0xE0, 0x23, 0xF1, 0x17, 0xFA,
	0x07, 0xF8, 0x07, 0xF8, 0x17, 0xFA, 0x23, 0xF1, 0x01, 0xE0, 0x08, 0x04,
	0x11, 0x22, 0x02, 0x10
];

export const privdib01 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x06, 0xFC, 0x09, 0x02, 0x08, 0x01, 0x06, 0x81, 0x00, 0x42,
	0x00, 0x24, 0x12, 0x58, 0x0A, 0x80, 0x07, 0x00, 0x1F, 0xC0, 0x07, 0x00,
	0x0A, 0x80, 0x12, 0x40
];

export const privdib02 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x0D, 0xFC, 0x12, 0x02,
	0x20, 0x01, 0x21, 0x01, 0x12, 0x82, 0x0C, 0x44, 0x00, 0x38, 0x00, 0x00,
	0x00, 0x00, 0x00, 0x00
];

export const privdib03 = [
	// Offset 0x00000000 to 0x0000001B
	0x00, 0x00, 0x15, 0x14, 0x00, 0x84, 0x08, 0x48, 0x0A, 0x4A, 0x02, 0x12,
	0x00, 0x00, 0x0D, 0xFC, 0x12, 0x02, 0x11, 0x02, 0x13, 0x02, 0x0C, 0x84,
	0x00, 0x78, 0x00, 0x00
];
