<div class="container-fluid">
    <div class="row">
        <nav class="col-md-3 col-lg-2 min-vh-100 p-0 d-md-block sidebar collapse bg-white">
            
            <div class="d-flex pt-4 pb-4">
                <a class="navbar-brand ms-5" [routerLink]="['/products']">
                    <img class="m-auto" src="assets/img/tecneplas-brand.svg" width="160">
                </a>
            </div>
            
            <div class="position-sticky pt-3">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a class="nav-link p-0" aria-current="page" [ngClass]="{'tecneplas-current-page': currentWizardNumber === 0}">
                            <div class="pt-3 pb-3 ms-5">
                                1
                                <span class="text-secondary fw-bold fs-11 lh-2 ps-2 m-auto" i18n>Datos del distribuidor</span>
                            </div>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link p-0" [ngClass]="{'tecneplas-current-page': currentWizardNumber === 1}">
                            <div class="pt-3 pb-3 ms-5">
                                2
                                <span class="text-secondary fw-bold fs-11 lh-2 ps-2 m-auto" i18n>Datos del usuario</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>

        <main class="col-md-9 ms-sm-auto col-lg-10">
            <div class="text-end m-4">
                <a class="text-primary" [routerLink]="['/distributors']">
                    <i class="bi bi-x-circle fs-1"></i>
                </a>
            </div>
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-6">
                    <div class="mt-5">
                        <div *ngIf="currentWizardNumber === 0">
                            <form #distributorNgForm="ngForm" [formGroup]="distributorForm" (ngSubmit)="distributorNgForm.form.valid && submitDistributorForm()">
                                    <h5 i18n>Nuevo distribuidor</h5>
                                    <p class="fs-10" i18n>Para empezar, añade los siguientes datos</p>

                                    <div class="col">
                                        <label class="text-secondary fw-bold fs-11 lh-2" i18n>Nombre</label>
                                        <input type="text" class="form-control form-control-lg" required formControlName="name" [ngClass]="[distributorNgForm.submitted && distributorForm.controls.name.errors?.required ? 'is-invalid' : '']">
                                        <div class="invalid-feedback" *ngIf="distributorForm.controls.name.errors?.required" i18n>Debe introducir un nombre</div>
                                    </div>

                                    <div class="col mt-4 mb-4">
                                        <label class="text-secondary fw-bold fs-11 lh-2" i18n>Dirección</label>
                                        <input type="text" class="form-control form-control-lg" required formControlName="address" [ngClass]="[distributorNgForm.submitted && distributorForm.controls.address.errors?.required ? 'is-invalid' : '']">
                                        <div class="invalid-feedback" *ngIf="distributorForm.controls.address.errors?.required" i18n>Debe introducir una dirección</div>
                                    </div>
                                    
                                    <div class="col mt-4 mb-4">
                                        <label class="text-secondary fw-bold fs-11 lh-2" i18n>Correo Responsable</label>
                                        <input type="text" class="form-control form-control-lg" required formControlName="email" [ngClass]="[distributorNgForm.submitted && distributorForm.controls.email.errors?.required ? 'is-invalid' : '']">
                                        <div class="invalid-feedback" *ngIf="distributorForm.controls.email.errors?.required" i18n>Debe introducir un correo</div>
                                    </div>

                                    <button class="mt-1 btn btn-lg btn-outline-primary me-2" disabled i18n>Atrás</button>
                                    <button class="mt-1 btn btn-lg btn-primary" type="submit" i18n>Continuar</button>
                            </form>
                        </div>
                        <div *ngIf="currentWizardNumber === 1">
                            <form #userDistributorNgForm="ngForm" [formGroup]="userDistributorForm" (ngSubmit)="userDistributorNgForm.form.valid && submitUserDistributorForm()">
                                <h5 i18n>Nuevo usuario</h5>
                                <p class="fs-10" i18n>Para empezar, añade los siguientes datos</p>

                                <div class="col">
                                    <label class="text-secondary fw-bold fs-11 lh-2" i18n>Nombre</label>
                                    <input type="text" class="form-control form-control-lg" required formControlName="name" [ngClass]="[userDistributorNgForm.submitted && userDistributorForm.controls.name.errors?.required ? 'is-invalid' : '']">
                                    <div class="invalid-feedback" *ngIf="userDistributorForm.controls.name.errors?.required" i18n>Debe introducir un nombre</div>
                                </div>

                                <div class="col mt-4">
                                    <label class="text-secondary fw-bold fs-11 lh-2" i18n>Appellidos</label>
                                    <input type="text" class="form-control form-control-lg" required formControlName="surname" [ngClass]="[userDistributorNgForm.submitted && userDistributorForm.controls.surname.errors?.required ? 'is-invalid' : '']">
                                    <div class="invalid-feedback" *ngIf="userDistributorForm.controls.surname.errors?.required" i18n>Debe introducir un apellido</div>
                                </div>

                                <div class="col mt-4 mb-4">
                                    <label class="text-secondary fw-bold fs-11 lh-2" i18n>Correo</label>
                                    <input type="text" class="form-control form-control-lg" required formControlName="email" [ngClass]="[userDistributorNgForm.submitted && userDistributorForm.controls.email.errors?.required ? 'is-invalid' : '' || userDistributorForm.controls.email.errors?.invalidEmail ? 'is-invalid' : '']">
                                    <div class="invalid-feedback" *ngIf="userDistributorForm.controls.email.errors?.required" i18n>Debe introducir un correo</div>
                                    <div class="invalid-feedback" *ngIf="userDistributorForm.controls.email.errors?.invalidEmail" i18n>Correo registrado.</div>
                                </div>

                                <button class="mt-1 btn btn-lg btn-outline-primary me-2" (click)="previousCurrentWizard()" i18n>Atrás</button>
                                <button class="mt-1 btn btn-lg btn-primary" type="submit" i18n>Enviar</button>
                            </form>
                        </div>
                        <div class="text-center" *ngIf="currentWizardNumber === 2">
                            <p ><i class="bi bi-check2 h1 text-success"></i></p>
                            <p class="fs-10 mb-4 mt-4" i18n>¡Perfecto! Has añadido el distribuidor {{distributor.name}} con el usuario {{account.email}}.</p>
    
                            <button type="button" class="btn btn-lg btn-primary w-75" [routerLink]="['/distributors']" i18n>Volver al listado de distribuidores</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-3"></div>
            </div>
        </main>

    </div>
</div>