<div class="container">
    <div class="row  align-items-center">
      <br><br><br><br>
    </div>
  
    <div class="jumbotron">
      <h1>Error 404</h1>
      <p class="lead" i18n>Página no encontrada</p>
      <a class="btn btn-primary" [routerLink]="['/products']" role="button" i18n>Ir a productos</a>
    </div>
  </div>
  