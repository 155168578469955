<div class="pt-3 bg-body">
    <div class="row">
        <div class="col-12 col-sm-8 col-md-8	col-lg-8 col-xl-8 col-xxl-8 mt-4">
            <h4 class="fs-10 lh-3 fw-bold" i18n>Tus secuencias ({{sequenceCount}})</h4>
        </div>
        <div class="col-12 col-sm-4 col-md-4 col-lg-10 col-xl-4 col-xxl-4 text-start text-sm-end mt-2">
            <button type="button" class="btn btn-outline-primary" (click)="openAddSequenceModal(addSequenceModal)">
                <svg class="me-2" width="12" height="12" viewBox="0 0 12 12" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M10.6667 5.33335H6.66675V1.33335C6.66675 0.965164 6.36827 0.666687 6.00008 0.666687C5.63189 0.666687 5.33341 0.965164 5.33341 1.33335V5.33335H1.33341C0.965225 5.33335 0.666748 5.63183 0.666748 6.00002C0.666748 6.36821 0.965225 6.66669 1.33341 6.66669H5.33341V10.6667C5.33341 11.0349 5.63189 11.3334 6.00008 11.3334C6.36827 11.3334 6.66675 11.0349 6.66675 10.6667V6.66669H10.6667C11.0349 6.66669 11.3334 6.36821 11.3334 6.00002C11.3334 5.63183 11.0349 5.33335 10.6667 5.33335Z"
                        fill="#2C2A86" />
                </svg>
                <span i18n>Nueva secuencia</span>
            </button>
        </div>
    </div>

    <div class="row mb-2" *ngFor="let sequence of sequences">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
            <span class="text-secondary fw-bold">{{ sequence.name }}</span>
            <hr>
        </div>

        <div class="row">
            <!-- start: Add new sequence button -->
            <div class="col-12 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1 mb-2 align-self-stretch">
                <div class="border rounded border-dashed cursor-pointer bg-light d-flex h-100"
                    (click)="openAddSequenceModal(addSequenceModal, sequence)">
                    <span class="m-auto"><i class="bi bi-plus-lg"></i></span>
                </div>
            </div>
            <!-- end: Add new sequence button -->

            <!-- start: List of sequence by category -->
            <div class="col-12 col-sm-11">
                <div class="row h-100">
                    <div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-2 align-self-stretch"
                        *ngFor="let sequence_item of sequence.list">
                        <div class="border-start border-primary border-4 rounded p-2 cursor-pointer h-100 bg-white shadow-lg secuence-card"
                            [ngClass]="{'border-primary': sequence.category === 'daily', 'border-fest' : sequence.category === 'holiday', 'border-guard' : sequence.category === 'on_guard' }"
                            [routerLink]="['sequence', sequence_item._id, 'actions']">
                            <div class="row">
                                <div class="col-9">
                                    <span class="text-primary fw-bold d-block text-truncate">{{ sequence_item.name
                                        }}</span>
                                    <p class="text-secondary">Tipo: {{sequence.name}}<br>
                                        <span *ngIf="sequence_item.default" class="text-secondary" i18n>Predeterminada</span>
                                    </p>
                                </div>
                                <div class="col-3">
                                    <div class="btn-group dropdown float-end">
                                        <a data-bs-toggle="dropdown" aria-expanded="false"
                                            (click)="$event.stopPropagation()">
                                            <div class="btn btn-outline-primary"><i
                                                    class="bi bi-three-dots-vertical"></i></div>
                                        </a>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <li><button class="dropdown-item cursor-pointer"
                                                    (click)="duplicateSequence($event, sequence_item)" i18n>Duplicar</button>
                                            </li>
                                            <li><button class="dropdown-item cursor-pointer"
                                                    (click)="deleteSequence($event, sequence_item)" i18n>Eliminar</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end: List of sequence by category -->
        </div>
    </div>
</div>

<!-- start: Modal for adding new sequence -->
<ng-template #addSequenceModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title " id="modal-basic-title" i18n>Crear secuencia</h4>
    </div>
    <form [formGroup]="sequenceForm" (ngSubmit)="submitForm()">
        <div class="modal-body">
            <div class="mb-3">
                <label for="formCategory" class="form-label" i18n>Tipo</label>
                <select id="formCategory" class="form-select" [ngModel]="defaultCategory" formControlName="category">
                    <option *ngFor="let category of categories" [value]="category.id">{{ category.name }}</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="formName" class="form-label" i18n>Nombre</label>
                <input type="text" class="form-control" id="formName" placeholder="Ej.: Buenos días"
                    formControlName="name">
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-primary me-auto" (click)="modal.dismiss('Close button click')"
                i18n>Cerrar</button>
            <button type="submit" class="btn btn-primary" [disabled]="!sequenceForm.valid" i18n>Crear secuencia</button>
        </div>
    </form>
</ng-template>
<!-- end: Modal for adding new sequence -->

<!-- start: Modal for deleting sequence -->
<ng-template #deleteSequenceModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title " id="modal-basic-title" i18n>Eliminando secuencia</h4>
    </div>

    <div class="modal-body">
        <div class="mb-3">
            <p *ngIf="location" i18n>Esta sequencia esta en uso en el {{location}}. ¿Seguro que quiere eliminarla?</p>
            <p *ngIf="!location" i18n>¿Seguro que quiere eliminar la sequencia?</p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger me-auto" (click)="acceptDeleteSequence(sequence)"
            i18n>Eliminar</button>
        <button type="button" class="btn btn-outline-primary" (click)="modal.dismiss('Close button click')"
            i18n>Cerrar</button>
    </div>
</ng-template>
<!-- end: Modal for deleting sequence -->